import { Link } from "react-router-dom";

const Navigation = () => {
    return ( 
        <nav className="project-nav">
	<div className="project-nav__links-wrapper">
		<Link to="/">Форма добавления заявок</Link>
		<Link to="/table">Таблица с заявками</Link>
		
		{/* <a href="#" style={{ pointerEvents: 'none' }}>Редактирование заявки</a> */}
	</div>
</nav>
     );
}
export default Navigation;

