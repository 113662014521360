const renderPriduct = {
    'course-php': 'Курс по PHP',
    "course-js":'Курс по JavaScript',
    "course-vue":'Курс по VUE JS',
    "course-wordpress":'Курс по WordPress',
    "course-html": 'Курс по верстке',
};

const renderState = {
    "new":     'Новая',
    "inwork":  'В работе',
    "complete":'Завершена'
}
//фон надписи статус
const badgeColor = {
    "new":      'badge-danger',
    "inwork":   'badge-warning',
    "complete": 'badge-success'
}

let currentDate, currentTimeMoscow;     
const  makeDate = ()=> {
   const options = {day: '2-digit', month: '2-digit',year: 'numeric'};
   return  currentDate = new Date().toLocaleDateString('ru-RU', options);  
    };

const  makeTime = ()=>  currentTimeMoscow = new Date().toLocaleString('ru-RU', { timeZone: 'Europe/Moscow' });

// const serverAdress = "https://grizzly-boiled-broccoli.glitch.me/posts";
const serverAdress = "https://market.teplo-best.ru/posts";
// const serverAdress = "http://localhost:8000/posts";


export {renderPriduct, renderState,badgeColor, makeDate, makeTime,currentDate,currentTimeMoscow, serverAdress}