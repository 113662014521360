import { Link } from "react-router-dom";

const Error404 = () => {
    return ( 
        <div className="not-found">
             <h2> 404 </h2>  
             <p>Страница не найдена</p>
             <Link to='/'>Перейти на главную страницу</Link> 
        </div>
     );
}
export default Error404;

