import { Link } from "react-router-dom";
import * as friend from './../friends.js'
    const BlogList = (props) => {

        return (
         <tbody id="tbody">
                {props.blog.map((post) =>
                    
                    <tr key={post.id}> 
                        <th scope="row" >{post.id}</th>
                        <td>{post.date}</td>
                        <td>{friend.renderPriduct[post.product]}</td>
                        <td> {post.personName}</td>
                        <td>{post.mail}</td>
                        <td>{post.phone}</td>
                        <td>
                            <div className={`badge badge-pill ${friend.badgeColor[post.state]}`} >{friend.renderState[post.state]}</div>
                        </td>
                        <td>
                             <Link to={`/edit/${post.id}`}>
                                <p>Редактировать</p>
                            </Link>
                        </td>
                    </tr>
                        
                )}
                </tbody>
        )
    }
    export default BlogList;