import { useEffect, useState } from "react";
import * as friend from './../friends.js';
import { serverAdress } from "../friends";

const Card = () => {
    
      //для блокировки кнопки отправки на то время, пока уже отправленный пост не будет записан на сервер
    const [isPending, setPending] = useState(false);//тут  будет false или true

    const names = ['Марфа Васильевна', 'Вася Грязный', 'Лиля Брикман' ];
    const phones = ["+788114577",  "+78174445777", "88127445544"];
    const mailes = ['aff@dfe.com', "fgf@mail.su", "122@bk.tr"];
    const products = ['course-js', "course-php", "course-wordpress","course-vue", "course-html"];
    const randomValue = (array) => array[Math.floor(Math.random() * array.length)];

    const [personName, setName] = useState(randomValue(names));
    const [phone, setPhone] = useState(randomValue(phones));
    const [mail, setMail] = useState(randomValue(mailes));
    const [product, setProduct] = useState(randomValue(products));
    const [state] = useState('new');
    const [date, setDate] = useState(friend.makeDate(friend.currentDate));
    const [time, setTime] = useState(friend.makeTime(friend.currentTimeMoscow));

    const  handleSubmit = (event) => {
       event.preventDefault();

const blog = {personName, phone, mail, product, state, date, time};

if (blog.personName) {
setPending(true);
fetch(serverAdress, {
    method:'POST',
    headers: {"Content-Type": "application/json"}, 
    body: JSON.stringify(blog)
   }).then((res)=> {
    if (res.ok){
        setProduct(randomValue(products));
        setMail(randomValue(mailes));
        setPhone(randomValue(phones));
        setName(randomValue(names));
        setProduct(randomValue(products));
        setDate(friend.makeDate(friend.currentDate));
        setTime(friend.makeTime(friend.currentTimeMoscow));
        setPending(false)
    }

   }).catch(error => {
    console.error("Ошибка при выполнении отправки на сервер: ", error);
});
}}

useEffect(()=>{
    document.body.className="with-nav radial-bg flex-center"
},[]);
    return ( 
        <div className="white-plate white-plate--payment">
            <div className="container-fluid">
                
                <div className="white-plate__header text-center">
                    <p className="white-plate__logo">
                        <span>Форма</span> заявок
                    </p>
                </div>
        
                <div className="white-plate__line-between white-plate__line-between--main"></div>
                <form onSubmit={handleSubmit} id="form" method="POST" action="">
                    <label>Ваши данные:</label>
                    <div className="form-group">
                        <input id="name" type="text" name="name" autoComplete="on" className="form-control" placeholder="Имя и Фамилия" required
                        value={personName} onChange={(e) =>{setName(e.target.value)}}
                        />
                    </div>
                    <div className="form-group">
                        <input id="phone" type="text" name="phone" autoComplete="on" className="form-control" placeholder="Телефон"
                        value={phone}  onChange={(e) =>{setPhone(e.target.value)}}
                        />
                    </div>
                    <div className="form-group">
                        <input id="email" type="email" name="email" autoComplete="on" className="form-control" placeholder="Email" required
                        value={mail} onChange={(e) =>{setMail(e.target.value)}} 
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlSelect1">Продукт:</label>
                        <select id="product" name="product" className="form-control"
                         value={product} onChange={(e) =>{setProduct(e.target.value)}}>
                            <option value="course-html">Курс по верстке</option>
                            <option value="course-js">Курс по JavaScript</option>
                            <option value="course-vue">Курс по VUE JS</option>
                            <option value="course-php">Курс по PHP</option>
                            <option value="course-wordpress">Курс по WordPress</option>
                        </select>
                    </div>
                    <div className="form-group">
                      {isPending && <button type="submit" disabled className="btn btn-lg btn-primary btn-block">Заявка оформляется</button>}  
                      {!isPending && <button type="submit"  className="btn btn-lg btn-primary btn-block">Оформить заявку</button>}  
                    </div>
                </form>
        
            </div>
        </div>
        );
}
 
export default Card;
