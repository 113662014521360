import {useEffect, useState} from 'react';
import { useParams, useNavigate } from "react-router-dom";
import {Delete} from './DeleteCard';
import { serverAdress } from '../friends';

 const Editor = () => {
        const navigate = useNavigate();
        const {id} = useParams();//для перехода сюда поо айди - см Арр
        const [posts, setPosts] = useState(null);
        
        useEffect (() => {
   
            fetch( serverAdress+`/${id}`).then((res) =>{
               return  res.json()
            }).then(data =>{
             setPosts(data)
            })
             }, [] 
        );
        
function saving() {
        fetch( serverAdress+`/${id}`, {
            method: 'PATCH',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(posts)
          })
          .then(response => response.json())
          .catch((error) => {
            console.error('Ошибка при обновлении содержания поста:', error);
          });
}

useEffect(()=>{
    document.body.className="with-nav"
},[]);

   if (posts) {     
    
 return(
< >
<div className="form-wrapper">
    <div className="container-fluid">

        <div className="row justify-content-between align-items-center">
            <div className="col">
                <div className="admin-heading-1">Работа с заявкой</div>
            </div>
                <div className="col text-right">
                <button  className="btn btn-warning" onClick= {() => {Delete(id); navigate("/table")}}>Удалить заявку</button>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <form id="form">
                    <div className="card mb-4">
                        <div className="card-header">Данные о заявке</div>
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-2">
                                    <strong>ID:</strong>
                                </div>
                                <div className="col">Заявка № <span id="number">{posts.id}</span></div>
                                <input name="id" type="hidden" id="id"/>
                            </div>

                            <div className="row mb-3">
                                <div className="col-md-2">
                                    <strong>Дата создания:</strong>
                                </div>
                                <div className="col" id="date">{posts.time} </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-md-2">
                                    <strong>Продукт:</strong>
                                </div>
                                <div className="col">
                                  <select id="product" name="product" className="custom-select" value={posts.product}  onChange={(e)=>{setPosts({...posts, product: e.target.value})}}>
                                    <option value="course-html">     Курс по верстке</option>
                                    <option value="course-js">       Курс по JavaScript</option>
                                    <option value="course-vue">      Курс по VUE JS</option>
                                    <option value="course-php">      Курс по PHP</option>
                                    <option value="course-wordpress">Курс по WordPress</option>
                                  </select>
                                </div>
                            </div>
                       
                            <div className="row mb-3">
                                <div className="col-md-2">
                                    <strong>Имя:</strong>
                                </div>
                                <div className="col">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={posts.personName}
                                        id="name"
                                        name="name"
                                        onChange={(e)=>{setPosts({...posts, personName: e.target.value})}}
                                        readOnly //если закоментить или стереть это, то поле будет изменяемым
                                    
                                    />
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-md-2">
                                    <strong>Email:</strong>
                                </div>
                                <div className="col">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={posts.mail}
                                        id="email"
                                        name="email"
                                        onChange={(e)=>{setPosts({...posts, mail: e.target.value})}}
                                        readOnly //если закоментить или стереть это, то поле будет изменяемым
                                        />
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-md-2">
                                    <strong>Телефон:</strong>
                                </div>
                                <div className="col">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={posts.phone}
                                        id="phone"
                                        name="phone"
                                        onChange={(e)=>{setPosts({...posts, phone: e.target.value})}}
                                        readOnly //если закоментить или стереть это, то поле будет изменяемым
                                        />
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-md-2">
                                    <strong>Статус заявки:</strong>
                                </div>
                                <div className="col">
                                    <select className="custom-select" id="status" name="status" value={posts.state}  onChange={(e)=>{setPosts({...posts, state: e.target.value})}}>
                                                <option value="new">     Новая</option>
                                                <option value="inwork">  В работе</option>
                                                <option value="complete">Завершена</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-between">
                        <div className="col text-right">
                            <button  className="btn btn-primary" onClick= {() => {saving() }}>Сохранить изменения</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
</>
     );
    }
}
 
export default Editor;

