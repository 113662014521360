import { serverAdress } from "../friends";
import BlogList from "./BlogList";
import {useState, useEffect} from 'react';

const Table = () => {
	const [posts, setPosts] = useState(null);
	const [product, setProduct] = useState(null);
	const [fresh, setFresh] = useState(null);
	const [active, setActive] = useState('');
    const [isLoading, setLoading] = useState(true);
    const [isMistake, setMistake]= useState(true);
    const [flag, setFlag]= useState(true);
	useEffect (() => {

		fetch(serverAdress).then((res) =>{
		   return  res.json()
		}).then(data =>{
			setPosts(data);
			setProduct(data);
			setLoading(false)
		}).catch((err)=>{
			if (err.name == "AbortError") {
				console.log("запрос прерван");
			} else {
				 setMistake(err.message)
				 setLoading(false)
			}  
		});
		fetch(serverAdress+"?state=new").then((res) =>{
		   return  res.json()
		}).then(fresh =>{
			setFresh(fresh);
		}).catch((err)=>{
			if (err.name == "AbortError") {
				console.log("запрос прерван");
			} else {
				 setMistake(err.message)
				 setLoading(false)
			}
		});
		 }, [] 
	)

function settingStatus (statusVariant) {
	setFlag(false);
	fetch(statusVariant!=="All"? serverAdress+`?state=${statusVariant}`: serverAdress).then((res) =>{
		return  res.json()}).then(data =>{ setPosts(data); setLoading(false) }).catch((err)=>{
            if (err.name == "AbortError") {
                console.log("запрос прерван");
            } else {
                 setMistake(err.message)
                 setLoading(false)
            };
        });
setActive(statusVariant);

const selectElement = document.getElementById('productSelect');
const optionToSelect = selectElement.querySelector('#all');
optionToSelect.selected = true;
selectElement.dispatchEvent(new Event('change'));  
}	
	
function settingProduct (productVariant) {
	setFlag(true);
	setActive('');
	fetch(productVariant!=="All"? serverAdress+`?product=${productVariant}`: serverAdress).then((res) =>{
		return  res.json()}).then(prod =>{ setProduct(prod); setPosts(prod); setLoading(false) }).catch((err)=>{
            if (err.name == "AbortError") {
                console.log("запрос прерван");
            } else {
                 setMistake(err.message)
                 setLoading(false)
            }
        })
	}	
function settingProdStat (prodstatVariant) {
	setActive('');
	if (flag == true) {
		 prodstatVariant!=="All"? setPosts(product.filter((cart) => cart.state == prodstatVariant)): setPosts(product)
	} else return

	}	

useEffect (() => {

    fetch(serverAdress).then((res) =>{
       return  res.json()
    }).then(data =>{
		setPosts(data);
		setProduct(data);
		setLoading(false)
    }).catch((err)=>{
		if (err.name == "AbortError") {
			console.log("запрос прерван");
		} else {
			 setMistake(err.message)
			 setLoading(false)
		}
	});
    fetch(serverAdress+"?state=new").then((res) =>{
       return  res.json()
    }).then(fresh =>{
		setFresh(fresh);
    }).catch((err)=>{
		if (err.name == "AbortError") {
			console.log("запрос прерван");
		} else {
			 setMistake(err.message)
			 setLoading(false)
		}
	});
     }, [] 
)
useEffect(()=>{
    document.body.className="with-nav body--dashboard"
},[]);
	
    return ( 
<>
		<div className="left-panel blue-skin">
			<div className="left-panel__logo">
				<div className="left-panel__logo-title">CRM заявки</div>
				<div className="left-panel__logo-subtitle">учебный проект webcademy</div>
			</div>
			<div className="left-panel__user clearfix">
				<div className="left-panel__user-photo">
					<img src="./tina.jpeg" alt="Avatar" />
				</div>
				<div className="left-panel__user-name">Tina <br />Turner</div>
			</div>
			<div className="left-panel__navigation">
				<div className="left-panel__navigation-title">Заявки</div>
				<ul>
					<li><a data-value="all" data-role="left-status"      onClick={()=>{settingStatus("All")}} 	   className={active=="All" ? "active" : ""}>Все вместе</a></li>
					<li><a data-value="new" data-role="left-status"      onClick={()=>{settingStatus("new")}}      className={active=="new" ? "active" : ""}>Новые<div className="badge" id="badge-new"  >{fresh && fresh.length}</div></a></li>
					<li><a data-value="inwork" data-role="left-status"   onClick={()=>{settingStatus("inwork")}}   className={active=="inwork" ? "active" : ""}>В работе</a></li>
					<li><a data-value="complete" data-role="left-status" onClick={()=>{settingStatus("complete")}} className={active=="complete" ? "active" : ""}>Завершенные</a></li>
				</ul>
			</div>
		</div>
		
		<div className="main-wrapper">
			<div className="container-fluid">
				<div className="admin-heading-1">Все заявки</div>
				{isMistake && isLoading && <p>Загрузка...</p>}
				{!isMistake && isLoading && <p>Ошибка запроса на сервер</p>}
				<form action="">
					<div className="row mb-3 justify-content-start">
						<div className="col">
							{ flag ? 
									<div id="topStatusBar" className="btn-group" role="group" aria-label="...">
										<a  className="btn btn-light" data-value="all" onClick={()=>{settingProdStat("All")}}>Все</a>
										<a  className="btn btn-light" data-value="new" onClick={()=>{settingProdStat("new")}}>Новые</a>
										<a  className="btn btn-light" data-value="inwork" onClick={()=>{settingProdStat("inwork")}}>В работе</a>
										<a  className="btn btn-light" data-value="complete" onClick={()=>{settingProdStat("complete")}}>Завершенные</a>
									</div> 
								   :
									<div   role="group" aria-label="...">
										<a  className="btn opacity" data-value="all" >Все</a>
										<a  className="btn opacity" data-value="new" >Новые</a>
										<a  className="btn opacity" data-value="inwork" >В работе</a>
										<a  className="btn opacity" data-value="complete" >Завершенные</a>
									</div> 
							}
						</div>
						<div className="col">
							<select className="custom-select" id="productSelect"  onClick={(event) => {settingProduct(event.target.value)}}>
								<option value="All" id="all" >Все продукты</option>
								<option value="course-html">Курс по верстке</option>
								<option value="course-js">Курс по JavaScript</option>
								<option value="course-vue">Курс по VUE JS</option>
								<option value="course-php">Курс по PHP</option>
								<option value="course-wordpress">Курс по WordPress</option>
							</select>
						</div>
					</div>
				</form>
				<table className="table fs-14">
					<thead>
						<tr>
							<th>ID</th>
							<th>дата</th>
							<th>продукт</th>
							<th>имя</th>
							<th>email</th>
							<th>телефон</th>
							<th>статус</th>
							<th></th>
						</tr>
					</thead>
					
			{posts ?  <BlogList blog={posts}/> : <p> Ожидаем заявки...</p> }
					
				</table>
			</div>
		</div>

		</>
     );
	} 

export default Table;