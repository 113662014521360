import {HashRouter as Router, Routes, Route} from 'react-router-dom'
import Card from "./Card";
import Table from "./Table";
import Editor from "./Editor";
import Navigation from './Navigation';
import Error404 from '../Error404';

function App() {
	return (
		<Router>
				<Navigation/>
				<Routes>
					<Route path='/' element={<Card/>} />  
					<Route path='/table' element={<Table/>} />  
					<Route path='/edit/:id'  element={<Editor/>} />
					<Route path='*' element={<Error404/>} />
				</Routes>
		</Router>
)
}
export default App;

